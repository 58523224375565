import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import moment from 'moment';
export default {
  name: 'ExamResults',
  data() {
    return {
      examId: null,
      type: null,
      total: 0,
      studentList: [],
      current: 1,
      size: 30
    };
  },
  components: {},
  created() {
    this.examId = this.$route.params.examId;
    this.getStudent();
  },
  methods: {
    async getStudent() {
      let data = {
        examId: this.examId,
        page: {
          current: this.current,
          size: this.size
        }
      };
      const result = await this.Api.exammanage.queryExamScorePage(data);
      this.total = result.data.total;
      this.studentList = result.data.records;
    },
    downloadTemplate() {
      const res = this.Api.exammanage.getStudentTemplate({});
      this.downloadFile(res.data);
    },
    getDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    downloadFile(file) {
      const blob = new Blob([file]);
      const fileName = '成绩.xlsx';
      const elink = document.createElement('a');
      elink.download = fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    },
    handleSizeChange(val) {
      this.size = val;
      this.current = 1;
      this.getStudent();
    },
    async exportFile() {
      const res = await this.Api.exammanage.exportExamScoreList({
        examId: this.examId
      }, {
        responseType: 'blob'
      });
      this.downloadFile(res);
    },
    handleCurrentChange(val) {
      console.log(`当前 ${val} 页`);
      this.current = val;
      this.getStudent();
    },
    goBack() {
      this.$router.push('/admin/exam-manage');
    }
  }
};